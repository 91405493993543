import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FormikConfig } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { kebabCase, toLower } from 'lodash';
import { useRecoilValue } from 'recoil';
import { useFormDialog } from '@egym/ui';
import { extYup } from '@egym/utils';
import { CreateUseCaseForm } from '@components';
import { pageUrls } from '@constants';
import { sessionState } from '@globalState';
import { useErrorHandlers, useUseCase } from '@hooks';
import { CreateUseCaseFormValues, IntegrationType, UseCaseName, UseCaseNaming } from '@types';

const useCreateUseCaseForm = (redirectToTestCase: boolean = true, useCaseNaming?: UseCaseNaming) => {
  const { t } = useTranslation();
  const { createUseCase } = useUseCase();
  const navigate = useNavigate();
  const partnerConfig = useRecoilValue(sessionState.atoms?.selectedPartnerConfig);
  const { openFormDialog, closeFormDialog } = useFormDialog();
  const { onConflictError, onForbiddenError } = useErrorHandlers();

  const onSubmit = useCallback(
    async (values: CreateUseCaseFormValues, formHelpers: FormikHelpers<CreateUseCaseFormValues>) => {
      try {
        if (partnerConfig) {
          await createUseCase.mutateAsync(values);
        }
        closeFormDialog();
        if (redirectToTestCase) {
          navigate(
            pageUrls.integrationUseCaseUrl({
              type: toLower(kebabCase(values.type as IntegrationType)),
              usecase: toLower(kebabCase(values.name as UseCaseName)),
            }),
          );
        }
      } catch ({ response }) {
        if (response?.status === 409) {
          onConflictError(formHelpers, { name: 'usecase.message.createConflict' });
        }
        if (response?.status === 403) {
          onForbiddenError();
        }
      }
    },
    [partnerConfig, closeFormDialog, redirectToTestCase, createUseCase, navigate, onConflictError, onForbiddenError],
  );

  const createUseCaseFormProps = useMemo<FormikConfig<CreateUseCaseFormValues>>(
    () => ({
      initialValues: {
        type: useCaseNaming?.type || null,
        name: useCaseNaming?.name || null,
      },
      validationSchema: extYup.object().shape({
        type: extYup.string().required(t('common.validation.required')),
        name: extYup.string().required(t('common.validation.required')),
      }),
      onSubmit,
      enableReinitialize: true,
      validateOnChange: false,
      validateOnBlur: false,
      validateOnMount: false,
    }),
    [onSubmit, t, useCaseNaming],
  );
  const openCreateUseCaseFormDialog = useCallback(() => {
    openFormDialog({
      title: 'integration.label.new',
      formComponent: CreateUseCaseForm,
      formProps: createUseCaseFormProps,
      confirmText: 'usecase.label.prepare',
      showCancelButton: false,
      muiDialogProps: {
        ['data-testid']: 'create-usecase-form-dialog',
      },
    });
  }, [openFormDialog, createUseCaseFormProps]);

  return {
    openCreateUseCaseFormDialog,
  };
};
export default useCreateUseCaseForm;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/system';
import { BaseLink, Button, buttonClasses, Container, Icons, Stack, Typography } from '@egym/ui';
import { pageUrls } from '@constants';
import PartnerConfigsSelect from './components/PartnerConfigsSelect';
import { SubHeaderDrawer } from './components/SubHeaderDrawer';
import { UseSubHeaderProps } from './SubHeaderProps';

const MenuLink = styled(BaseLink)(({ theme }) => ({
  textDecoration: 'none',
  color: 'black',
  '&.active': {
    color: theme.palette.primary.main,
  },
  '&:hover': {
    color: theme.palette.primary.main,
  },
})) as any;

const SubHeader: React.FC<UseSubHeaderProps> = ({
  onCreateAppTestClick,
  showIntegrations,
  showConnectedGyms,
  showPartnerConfigsSelect,
  showNewTestButton,
  setDrawerOpen,
  isDrawerOpen,
  isIntegrationsSelected,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Container maxWidth="lg" sx={{ px: 0 }}>
        <Stack
          sx={{ width: '100%', py: 2.5 }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          data-testid="sub-header"
        >
          <Stack sx={{ flexBasis: '50%' }} direction="row" alignItems="center" spacing={10}>
            {showConnectedGyms && (
              <MenuLink component={NavLink} to={pageUrls.locationsList} data-testid="sub-header-dashboard-link">
                <Typography variant="body2Bold">{t('locations.title')}</Typography>
              </MenuLink>
            )}
            {showIntegrations && (
              <Button
                endIcon={isIntegrationsSelected ? <Icons.KeyboardArrowDown /> : <Icons.KeyboardArrowUp />}
                variant="text"
                sx={({ palette }) => ({
                  color: isIntegrationsSelected ? palette.primary.main : 'common.black',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: palette.primary.main,
                  },
                  [`.${buttonClasses.endIcon}`]: {
                    ml: 1.5,
                  },
                })}
                onClick={() => setDrawerOpen(!isDrawerOpen)}
                text={<Typography variant="body2Bold">{t('subHeader.integrations')}</Typography>}
                data-testid="sub-header-open-integrations-button"
              />
            )}
            {showPartnerConfigsSelect && <PartnerConfigsSelect />}
          </Stack>
          {showNewTestButton && (
            <Button
              sx={{
                px: 10,
                [`.${buttonClasses.endIcon}`]: {
                  ml: 1.5,
                },
              }}
              color="primary"
              text="common.label.newApiTest"
              variant="contained"
              onClick={onCreateAppTestClick}
              endIcon={<Icons.Add />}
              data-testid="sub-header-create-new-usecase-button"
            />
          )}
        </Stack>
      </Container>
      <SubHeaderDrawer open={isDrawerOpen} onClose={() => setDrawerOpen(false)} />
    </>
  );
};

export default SubHeader;

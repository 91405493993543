import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useRouter } from '@egym/ui';
import { isIntegrationPage, pageUrls } from '@constants';
import { sessionState } from '@globalState';
import { useCreateUseCaseForm } from '@hooks';
import { UserRole } from '@types';
import { UseSubHeaderResult } from '../../SubHeaderProps';

const useSubHeader = (): UseSubHeaderResult => {
  const { pathname } = useRouter();
  const { openCreateUseCaseFormDialog } = useCreateUseCaseForm();
  const userInfo = useRecoilValue(sessionState.atoms.userInfo);
  const moduleInfo = useRecoilValue(sessionState.atoms.moduleInfo);
  const partnerConfig = useRecoilValue(sessionState.atoms.selectedPartnerConfig);

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    setDrawerOpen(false);
  }, [pathname]);

  const showIntegrations = useMemo(
    () => Boolean(moduleInfo?.sandbox && partnerConfig?.integrations),
    [moduleInfo?.sandbox, partnerConfig?.integrations],
  );

  const showConnectedGyms = useMemo(
    () => Boolean(moduleInfo?.gyms && partnerConfig?.gyms),
    [moduleInfo?.gyms, partnerConfig?.gyms],
  );

  const showPartnerConfigsSelect = useMemo(() => Boolean(userInfo?.roles?.includes(UserRole.manager)), [userInfo]);
  const showNewTestButton = useMemo(
    () => Boolean(partnerConfig?.id && showIntegrations && isIntegrationPage(pathname)),
    [partnerConfig?.id, showIntegrations, pathname],
  );

  const isIntegrationsSelected = useMemo(
    () => isDrawerOpen || pathname.includes(pageUrls.usecases),
    [isDrawerOpen, pathname],
  );

  return {
    onCreateAppTestClick: openCreateUseCaseFormDialog,
    showIntegrations,
    showConnectedGyms,
    showPartnerConfigsSelect,
    showNewTestButton,
    isDrawerOpen,
    setDrawerOpen,
    isIntegrationsSelected,
  };
};

export default useSubHeader;

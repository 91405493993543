import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { useSnackbar } from '@egym/ui';
import {
  getActiveUseCase,
  getUseCaseHistory,
  getUseCaseStats,
  postFinishUseCase,
  postGenerateTestCaseData,
  postNewUseCase,
  postRepeatTestCase,
  postSkipTestCase,
} from '@api';
import { sessionState, useCasesHistoryTable } from '@globalState';
import { CreateUseCaseFormValues, UseCaseNaming } from '@types';
import { useErrorHandlers } from '../useErrorHandlers';
import { configsKeys } from '../usePartnerConfigs';

export const scenariosKeys = {
  all: (configId: number) => [...configsKeys.current(configId), 'usecases'] as const,
  active: (configId: number, useCaseNaming: UseCaseNaming) =>
    [...scenariosKeys.all(configId), 'active', useCaseNaming.type, useCaseNaming.name] as const,
  stats: (configId: number) => [...scenariosKeys.all(configId), 'stats'] as const,
  history: (configId: number, filters: any) => [...scenariosKeys.all(configId), 'history', { filters }] as const,
};

export const useSelectedUseCase = (partnerConfigId: number, useCaseNaming: UseCaseNaming) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { openSnackbar } = useSnackbar();
  const { onForbiddenError } = useErrorHandlers();

  const activeUseCaseQuery = useQuery(
    scenariosKeys.active(partnerConfigId, useCaseNaming),
    async () =>
      getActiveUseCase({
        queryParams: { ...useCaseNaming, partnerConfigId },
      }),
    {
      select: result => result.data,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: true,
      onError: ({ response }) => {
        if (response?.status === 403) {
          onForbiddenError();
        }
      },
    },
  );

  const finishUseCase = useMutation(
    async (usecaseId: number) =>
      postFinishUseCase({
        urlParams: {
          usecaseId,
        },
        queryParams: {
          partnerConfigId,
        },
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(scenariosKeys.stats(partnerConfigId));
        await queryClient.invalidateQueries(scenariosKeys.active(partnerConfigId, useCaseNaming));
        openSnackbar(t('usecase.message.finishSuccess'));
      },
      onError: ({ response }) => {
        if (response?.status === 403) {
          onForbiddenError();
        } else {
          openSnackbar(t('usecase.message.finishFailed'), { severity: 'error' });
        }
      },
    },
  );

  const repeatTestCase = useMutation(
    async ({ usecaseId, testcaseId }: { usecaseId: number; testcaseId: number }) =>
      postRepeatTestCase({
        urlParams: {
          usecaseId,
          testcaseId,
        },
        queryParams: {
          partnerConfigId,
        },
      }),
    {
      onSuccess: async ({ data }) => {
        await queryClient.invalidateQueries(scenariosKeys.stats(partnerConfigId));
        await queryClient.refetchQueries(scenariosKeys.active(partnerConfigId, useCaseNaming));
        openSnackbar(t('testcase.message.repeatSuccess', { name: t(`testcase.name.${data.name}`) }));
      },
      onError: ({ response }) => {
        if (response?.status === 403) {
          onForbiddenError();
        } else {
          openSnackbar(t('testcase.message.repeatFailed'), { severity: 'error' });
        }
      },
    },
  );

  const skipTestCase = useMutation(
    async ({ usecaseId, testcaseId }: { usecaseId: number; testcaseId: number }) =>
      postSkipTestCase({ urlParams: { usecaseId, testcaseId }, queryParams: { partnerConfigId } }),
    {
      onSuccess: async ({ data }) => {
        await queryClient.invalidateQueries(scenariosKeys.stats(partnerConfigId));
        await queryClient.refetchQueries(scenariosKeys.active(partnerConfigId, useCaseNaming));
        openSnackbar(
          t('testcase.message.skipSuccess', {
            name: t(`testcase.name.${data.name}`),
          }),
        );
      },
      onError: ({ response }) => {
        if (response?.status === 403) {
          onForbiddenError();
        } else {
          openSnackbar(t('testcase.message.skipFailed'), { severity: 'error' });
        }
      },
    },
  );

  const generateTestCaseData = useMutation(
    async ({ usecaseId, testcaseId, valueType }: { usecaseId: number; testcaseId: number; valueType: string }) =>
      postGenerateTestCaseData({
        urlParams: { usecaseId, testcaseId },
        queryParams: { valueType, partnerConfigId },
      }),
    {
      onSuccess: async ({ data }) => {
        await queryClient.refetchQueries(scenariosKeys.active(partnerConfigId, useCaseNaming));
        openSnackbar(
          t('testcase.message.generateSuccess', {
            name: t(`testcase.name.${data.name}`),
          }),
        );
      },
      onError: ({ response }) => {
        if (response?.status === 403) {
          onForbiddenError();
        } else {
          openSnackbar(t('testcase.message.generateFailed'), { severity: 'error' });
        }
      },
    },
  );

  return {
    activeUseCaseQuery,
    finishUseCase,
    repeatTestCase,
    skipTestCase,
    generateTestCaseData,
  };
};

export const useUseCase = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const partnerConfig = useRecoilValue(sessionState.atoms.selectedPartnerConfig);
  const moduleInfo = useRecoilValue(sessionState.atoms.moduleInfo);
  const { openSnackbar } = useSnackbar();
  const { onForbiddenError } = useErrorHandlers();

  const partnerConfigId = useMemo(() => partnerConfig?.id ?? -1, [partnerConfig]);

  const createUseCase = useMutation(
    async (data: CreateUseCaseFormValues) =>
      postNewUseCase({
        queryParams: { partnerConfigId },
        payload: data,
      }),
    {
      onSuccess: async response => {
        queryClient.setQueryData(scenariosKeys.active(partnerConfigId, response.data), () => response);
        await queryClient.invalidateQueries(scenariosKeys.stats(partnerConfigId));
        openSnackbar(t('usecase.message.createSuccess'));
      },
      onError: ({ response }) => {
        if (response?.status === 403) {
          onForbiddenError();
        } else if (response?.status === 409) {
          openSnackbar(t('usecase.message.createConflict'), { severity: 'error' });
        } else {
          openSnackbar(t('usecase.message.createFailed'), { severity: 'error' });
        }
      },
    },
  );

  const useCaseStatsQuery = useQuery(
    scenariosKeys.stats(partnerConfigId),
    async () => getUseCaseStats({ queryParams: { partnerConfigId } }),
    {
      select: result => result.data,
      enabled: false,
      keepPreviousData: true,
      refetchOnMount: false,
      onError: ({ response }) => {
        if (response?.status === 403) {
          onForbiddenError();
        }
      },
    },
  );

  const tableQueryParams = useRecoilValue(useCasesHistoryTable.selectors.queryParams);
  const useCaseHistoryQuery = useQuery(
    scenariosKeys.history(partnerConfigId, tableQueryParams),
    async () =>
      getUseCaseHistory({
        queryParams: { ...tableQueryParams, partnerConfigId },
      }),
    {
      select: result => result.data,
      enabled: Boolean(moduleInfo?.sandbox && partnerConfig?.integrations),
      keepPreviousData: true,
      refetchOnMount: false,
      onError: ({ response }) => {
        if (response?.status === 403) {
          onForbiddenError();
        }
      },
    },
  );

  return {
    createUseCase,
    useCaseStatsQuery,
    useCaseHistoryQuery,
  };
};

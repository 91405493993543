import React, { FC } from 'react';
import { Container, Drawer } from '@egym/ui';
import { headerHeight } from '../../../../../../../styles/constants';
import IntegrationsDrawerContent from '../IntegrationsDrawerContent';

type Props = {
  open: boolean;
  onClose: () => void;
};

const SubHeaderDrawer: FC<Props> = ({ open, onClose }) => (
  <Drawer
    PaperProps={{
      sx: { marginTop: `${headerHeight - 5}px`, zIndex: 1 },
    }}
    slotProps={{
      root: {
        style: { top: `${headerHeight - 5}px`, zIndex: 1 },
      },
      backdrop: {
        style: {
          top: `${headerHeight - 5}px`,
          zIndex: 1,
        },
      },
    }}
    anchor="top"
    open={open}
    onClose={onClose}
  >
    <Container maxWidth="lg">
      <IntegrationsDrawerContent />
    </Container>
  </Drawer>
);

export default SubHeaderDrawer;
